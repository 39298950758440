<template>
  <v-row dense>
    <v-col cols="12">
      <ProductsQueryFilter
        @submit="submit"
        @reset="clearFilter"
      />
    </v-col>
    <v-col cols="4">
      <ProductsQueryPriceCalculator v-model="rows" />
    </v-col>
    <v-col cols="8">
      <ProductsQueryCard
        v-for="(product) in products"
        :key="product.id"
        :product="product"
      />
    </v-col>
  </v-row>
</template>

<script>
import ProductsQueryCard from './ProductsQueryCard.vue'
import ProductsQueryPriceCalculator from './ProductsQueryPriceCalculator.vue'
import ProductsQueryFilter from './ProductsQueryFilter.vue'
export default {
  components: {
    ProductsQueryCard,
    ProductsQueryPriceCalculator,
    ProductsQueryFilter,
  },
  data: () => ({
    codes: null,
    products: [],
    rows: [],
    selectedPrint: null,
    fixedValue: 0,
    settings: null,
    isLoadingCalculator: false,
    filter: {
      supplier_status_id: null,
      product_id: null,
      category_id: null,
      segment_id: null,
      special_date_id: null,
      customization: {
        id: null,
        type: null,
        text: null,
      },
      priceRange: {
        from: null,
        to: null,
      },
    },
  }),
  methods: {
    async submit(inputFilter){

      this.products = [];

      const filterAndQuantities = {
        ...inputFilter,
        rows: this.rows,
      }

      console.log(filterAndQuantities)


      const response = await this.$api.post('/products/consult', filterAndQuantities)

      this.products = response.data

    },
    getFilter(objFilter) {
      this.$api
        .post('products/filter/budget', objFilter)
        .then((res) => {
          this.products = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
    },

    clearFilter() {
      this.products = []
    },
    addRow(rows) {
      this.rows = rows
      this.$emit('addRow', rows)
    },
    cardClicked(product, selectedTable, rows, selectedPrint) {
      this.$emit('cardClicked', product, selectedTable, rows, selectedPrint)
    },
    getPrintById(id) {
      this.isLoadingCalculator = true
      this.$api
        .get(`product_prints/${id}`)
        .then((res) => {
          this.selectedPrint = res.data.data
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          this.isLoadingCalculator = false
        })
    },

    setSelectedPrint(id) {
      if (id !== undefined) {
        this.getPrintById(id)
      } else {
        this.selectedPrint = null
      }
    },
    getSettings() {
      this.$api
        .get('settings')
        .then((res) => {
          this.settings = res.data.data
          this.fixedValue = parseFloat(res.data.data.fix_value)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    setFilter(objFilter) {
      this.filter = objFilter
      this.getFilter(objFilter)
    },
  },
}
</script>
