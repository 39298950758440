<template>
  <v-form
    ref="form"
    @submit.prevent="submit"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <AutocompleteSearchProducts v-model="product_id" />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <AutocompleteMixedCustomizations
          v-model="customization"
          :product-id="product_id"
        />
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <SelectSupplierStatus v-model="supplier_status_id" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <AutocompleteCategories
          v-model="category_id"
          type="1"
          label="Categoria"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <AutocompleteCategories
          v-model="segment_id"
          type="2"
          label="Segmento"
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <AutocompleteCategories
          v-model="special_date_id"
          type="3"
          label="Datas especiais"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        cols="12"
        md="6"
      >
        <ProductsQueryFilterPriceRange
          v-model="priceRange"
        />
      </v-col>
      <v-col
        class="d-flex align-self-end justify-end"
        cols="12"
        md="6"
      >
        <v-btn
          class="mr-2"
          rounded
          dense
          color="primary"
          outlined
          dark
          @click="reset"
        >
          Limpar filtro
        </v-btn>

        <v-btn
          type="submit"
          rounded
          dense
          color="primary"
        >
          Filtrar
        </v-btn>
      </v-col>
    </v-row>

    <v-row dense />
  </v-form>
</template>

<script>
import AutocompleteCategories from '../../bussiness-components/autocompletes/AutocompleteCategories.vue'
import AutocompleteMixedCustomizations from '../../bussiness-components/autocompletes/AutocompleteMixedCustomizations.vue'
import AutocompleteSearchProducts from '../../bussiness-components/autocompletes/AutocompleteSearchProducts.vue'
import SelectSupplierStatus from '../../bussiness-components/selects/SelectSupplierStatus.vue'
import ProductsQueryFilterPriceRange from './ProductsQueryFilterPriceRange.vue'

export default {
  components: {
    AutocompleteSearchProducts,
    SelectSupplierStatus,
    AutocompleteMixedCustomizations,
    ProductsQueryFilterPriceRange,
    AutocompleteCategories,
  },
  data: () => ({
    supplier_status_id: null,
    product_id: null,
    category_id: null,
    segment_id: null,
    special_date_id: null,
    customization: {
      id: null,
      type: null,
      text: null,
    },
    priceRange: {
      from: null,
      to: null,
    },
  }),
  computed: {},
  methods: {
    submit() {
      this.$emit('submit', {
        supplier_status_id: this.supplier_status_id,
        product_id: this.product_id,
        category_id: this.category_id,
        segment_id: this.segment_id,
        special_date_id: this.special_date_id,
        customization: this.customization,
        priceRange: this.priceRange,
      })
    },
    reset() {
      this.supplier_status_id = null
      this.product_id = null
      this.category_id = null
      this.segment_id = null
      this.special_date_id = null
      this.customization = {
        id: null,
        type: null,
        text: null,
      }
      this.priceRange = {
        from: null,
        to: null,
      }
    },
  },
}
</script>

<style scoped lang="scss">
.primary-border {
  border: 1px solid var(--v-primary-base);
}

.sub-category {
  font-size: 0.85em;
  color: #757575;
}
</style>
