<template>
  <v-card
    :class="'active-card'"
    elevation="1"
  >
    <v-list-item>
      <v-list-item-avatar
        class="elevation-3"
        color="grey lighten-4"
        size="80"
        rounded
      >
        <v-img
          v-if="product.images.length > 0"
          :src="$store.state.imagePath + product.images[0].src"
        />
        <v-icon
          v-else
          color="primary"
        >
          fas fa-image
        </v-icon>
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title
          class="text-caption font-weight-bold secondary--text"
        >
          {{ product.name }}
        </v-list-item-title>
        <v-list-item-subtitle class="text-caption">
          {{ product.code }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-simple-table
      v-if="product.rows.length > 0"
      fixed-header
      dense
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Qtd.</th>
            <th class="text-right">UN</th>
            <th class="text-right">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, idx) in product.rows"
            :key="idx"
          >
            <td class="text-caption secondary--text">
              {{ row.quantity }}
            </td>
            <td class="text-caption secondary--text text-right">
              {{ row.price | formatCurrency }}
            </td>
            <td class="text-caption secondary--text text-right">
              {{ row.total_price | formatCurrency(2) }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-divider class="mx-4" />

    <v-card-actions>
      <v-chip
        class="caption font-weight-bold"
        :color="vendorStatusColor"
        x-small
        dark
      >
        {{ vendorStatusLabel }}
      </v-chip>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-on="on"
            v-bind="attrs"
            class="caption font-weight-regular secondary--text"
          >
            {{ updatedTimeLabel }}
          </span>
        </template>
        <span>Data de atualização</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    updatedTimeLabel() {
      const date = new Date(this.product.update_time)
      return date.toLocaleDateString()
    },
    vendorStatusLabel() {
      const status = parseInt(this.product.vendor_status)
      switch (status) {
        case 1:
          return 'OK'
        case 2:
          return 'Consultar'
        case 3:
          return 'Sem estoque'
        case 4:
          return 'Fora de linha'
      }
      return 'Unkown'
    },
    vendorStatusColor() {
      const status = parseInt(this.product.vendor_status)

      switch (status) {
        case 1:
          return 'success'
        case 2:
          return 'blue'
        case 3:
          return 'error'
        case 4:
          return 'gray'
      }
      return 'Unkown'
    },
  },
  methods: {},
}
</script>

<style>
.active-card {
  border: 1px solid var(--v-primary-base) !important;
}
</style>
