<template>
  <v-autocomplete
    v-model="selected"
    :loading="isLoading"
    :items="filteredList"
    :search-input.sync="search"
    return-object
    :label="label"
    outlined
    rounded
    dense
    @input="onSelect"
    @update:search-input="searchItems"
    @focus.once="searchItems"
  >
    <template v-slot:no-data>
      <div class="pa-2">
        <v-progress-linear
          v-if="isLoading"
          indeterminate
          color="primary"
        />
        <h6 class="text-subtitle-2 text-center">
          Nenhuma categoria encontrada
        </h6>
      </div>
    </template>
    <template v-slot:item="data">
      <v-list-item
        v-bind="data.attrs"
        v-on="data.on"
      >
        <v-list-item-content style="position: relative">
          <v-icon
            v-if="data.item.indent"
            small
            style="position: absolute; left: 0"
          >
            mdi-subdirectory-arrow-right
          </v-icon>
          <v-list-item-title
            :class="{ 'sub-category': data.item.indent }"
            style="margin-left: 24px"
          >
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="data.item.type_label"
            style="font-size: 80%; margin-left: 24px"
          >
            {{ data.item.type_label }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    value: {
      type: [Object, Number],
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    type: {
      type: [Number, String],
      default: 0,
    },
    label: {
      type: String,
      default: 'Categoria',
    },
  },
  data() {
    return {
      selected: { id: null, text: null },
      search: null,
      isLoading: false,
      focused: false,
      items: [],
      typesMap: {
        1: 'Principal',
        2: 'Segmento',
        3: 'Data Especial',
      },
    }
  },
  computed: {
    shouldFilterByType() {
      return this.type != 0
    },
    typeId() {
      return parseInt(this.type)
    },
    filteredList() {
      return this.items.reduce((list, category) => {
        if (this.shouldFilterByType && category.type !== this.typeId) {
          return list
        }
        list.push({ ...category, text: category.name, type_label: this.typesMap[category.type] })

        if (category.subcategories && category.subcategories.length) {
          const subcategories = category.subcategories.map((subcategory) => ({
            ...subcategory,
            text: subcategory.name, 
            type_label: this.typesMap[subcategory.type],
            indent: true,
          }))
          list.push(...subcategories)
        }

        return list
      }, [])
    },
  },
  methods: {
    onSelect() {
      if (this.returnObject) {
        this.$emit('input', this.selected)
        return
      }
      this.$emit('input', this.selected.id)
    },
    async fetchItems() {
      try {
        this.isLoading = true

        const response = await this.$api.get('/categories/main_categories')

        this.items = response.data.data
      } finally {
        this.isLoading = false
      }
    },
    searchItems: debounce(function () {
      this.fetchItems()
    }, 500),
  },
}
</script>
