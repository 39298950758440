<template>
  <v-select
    v-model="selected"
    label="Status do Fornecedor"
    :items="statuses"
    item-text="title"
    return-object
    color="primary"
    outlined
    rounded
    dense
    flat
    @input="onSelect"
  >
    <template v-slot:item="{ item }">
      <v-list-item-avatar size="20">
        <v-icon
          small
          :color="item.color"
        >
          {{ item.icon }}
        </v-icon>
      </v-list-item-avatar>
      <v-list-item-title>{{ item.title }}</v-list-item-title>
    </template>
  </v-select>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      statuses: [
        { id: 1, title: 'Ok', icon: 'fas fa-check', color: 'success' },
        { id: 2, title: 'Consultar', icon: 'fas fa-search', color: 'blue' },
        {
          id: 3,
          title: 'Sem estoque',
          icon: 'fas fa-box-open',
          color: 'error',
        },
        {
          id: 4,
          title: 'Fora de linha',
          icon: 'fas fa-times-circle',
          color: 'grey',
        },
      ],
    }
  },
  methods:{
    onSelect() {
      this.$emit('input', this.selected.id)
    },
  }
}
</script>
