<template>
  <div>
    <h6 class="text-subtitle-2">Preço de venda</h6>
    <v-row>
      <v-col cols="6">
        <InputMoney
          v-model="from"
          label="De :"
          placeholder="Preço de venda inicial"
          color="primary"
          outlined
          rounded
          dense
          flat
          hide-details
          @input="onChange"
        />
      </v-col>
      <v-col cols="6">
        <InputMoney
          v-model="to"
          label="Até :"
          placeholder="Preço de venda limite"
          color="primary"
          outlined
          rounded
          dense
          hide-details
          flat
          @input="onChange"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import InputMoney from '../../bussiness-components/inputs/InputMoney.vue';

export default {
  components:{
    InputMoney
  },
  data() {
    return {
      from: null,
      to: null,
    }
  },
  methods: {
    onChange() {
      this.$emit('input', {
        from: this.from,
        to: this.to,
      })
    },
  },
}
</script>
