<template>
  <v-autocomplete
    v-model="selected"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    return-object
    label="Gravação / Tabela"
    outlined
    rounded
    dense
    @input="onSelect"
    @update:search-input="searchItems"
    @focus.once="searchItems"
  >
    <template v-slot:no-data>
      <div class="pa-2">
        <h6 class="text-subtitle-2 text-center">Nenhuma item encontrado</h6>
      </div>
    </template>

    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
        <v-list-item-subtitle>
          {{ data.item.type }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import debounce from 'lodash/debounce'
export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    productId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selected: { id: null, text: null },
      search: null,
      isLoading: false,
      focused: false,
      items: [],
    }
  },
  watch: {
    productId() {
      this.fetchItems()
    },
  },
  methods: {
    onSelect() {
      this.$emit('input', this.selected)
    },
    async fetchItems() {
      if (this.search !== null && this.search === this.selected.text) {
        return
      }
      try {
        this.isLoading = true
        const response = await this.$api.get('/search/customizations/mixed', {
          params: {
            page: 1,
            search: this.search,
            product_id: this.productId,
          },
        })
        this.items = response.data
      } finally {
        this.isLoading = false
      }
    },
    searchItems: debounce(function () {
      this.fetchItems()
    }, 500),
  },
}
</script>
