<template>
  <v-card
    class="text-center"
    color="grey lighten-4"
  >
    <v-list-item>
      <v-list-item-title class="subtitle-2 secondary--text">
        Calcular preço de venda
      </v-list-item-title>
    </v-list-item>

    <v-list dense>
      <v-list-item
        v-for="(row, idx) in rows"
        :key="idx"
      >
        <v-list-item-content>
          <v-list-item-title class="d-flex align-center">
            <v-text-field
              v-model="row.quantity"
              label="Qtd."
              outlined
              rounded
              dense
              hide-details
              class="mt-1"
              type="number"
            />
            <v-btn
              icon
              @click="removeRow(row)"
            >
              <v-icon
                small
                color="error"
              >
                fas fa-times
              </v-icon>
            </v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-btn
          class="primary"
          dark
          icon
          @click="addRow"
        >
          <v-icon small>
            fas fa-plus
          </v-icon>
        </v-btn>
      </v-list-item>
      <v-list-item v-if="isLoading">
        <v-progress-linear indeterminate />
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,
  },
  data: () => ({
    rows: [],
  }),

  methods: {
    addRow() {
      this.rows.push({
        quantity: 100,
      })
      this.$emit('input', this.rows)
    },
    removeRow(row) {
      let idx = this.rows.indexOf(row)
      this.rows.splice(idx, 1)
      this.$emit('input', this.rows)
    },
  },
}
</script>
