<template>
  <div>
    <v-text-field
      :value="formattedValue"
      :disabled="disabled"
      :label="label"
      :placeholder="placeholder"
      :color="color"
      :outlined="outlined"
      :rounded="rounded"
      :dense="dense"
      :flat="flat"
      :hide-details="hideDetails"
      @input="onChange"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    decimals: {
      type: Number,
      default: 3,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    label:{
        type: String,
        required: true,
    },
    placeholder:{
        type: String,
        default: null,
    },
    color:{
        type: String,
        default: null,
    },
    outlined: {
        type: Boolean,
        default: false,
    },
    rounded: {
        type: Boolean,
        default: false,
    },
    dense: {
        type: Boolean,
        default: false,
    },
    flat: {
        type: Boolean,
        default: false,
    },
    hideDetails: {
        type: Boolean,
        default: false,
    },
  },
  data() {
    return {
      cents: null,
    }
  },
  computed: {
    formattedValue() {
      if (this.cents == null) {
        return null
      }

      return this.formatCurrency(this.cents, this.decimals)
    },
  },
  methods: {
    onChange(newValue) {
      if (newValue == null || newValue == '') {
        this.cents = null
        this.$emit('input', null)
        return
      }

      const cents = this.unformatCurrency(newValue, this.decimals)

      if (cents == 0) {
        this.cents = null
        this.$emit('input', null)
        return
      }

      this.$emit('input', cents)

      this.cents = cents
    },
  },
}
</script>
